import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'
import { useQueryParam, StringParam } from 'use-query-params'
import { Base64 } from 'js-base64'

import breakpoints from 'helpers/breakpoints'

import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import Section from 'components/atoms/layout/Section'
import SectionHeading from 'components/atoms/headings/SectionHeading'
import Flex from 'components/atoms/Flex'
import Box from 'components/atoms/Box'
import TableCell from 'components/atoms/table/TableCell'
import MiniProductImage from 'components/atoms/media/MiniProductImage'
import Layout from 'components/organisms/global/Layout'
import InternalButtonLink from 'components/atoms/buttons/InternalButtonLink'
import FormGridItem from 'components/atoms/forms/FormGridItem'

import { useCollectionActionsContext } from 'context/CollectionContext'
import TextField from '@material-ui/core/TextField'

const StyledIconButton = styled(IconButton)`
  border: 1px solid ${(props) => props.theme.colors.outlineDark};
`

const GridItem = styled(Grid)`
  padding-top: 10px !important;
  padding-bottom: 10px !important;
`

const Collection = ({ data }) => {
  const { getProducts } = useCollectionActionsContext()
  const [collectionObj, setCollectionObj] = useState(undefined)
  const [collectionData, setCollectionData] = useState(undefined)

  const [loaded, setLoaded] = useState(false)

  const [collection, setCollection] = useQueryParam('collection', StringParam)

  useEffect(() => {
    if (collection) {
      const collectionObj = JSON.parse(Base64.decode(collection))

      let productIds = []
      // create array of product ids for query
      collectionObj.collection.map((item) => {
        productIds.push(item.productId)
      })
      getProducts(productIds, false).then((result) => {
        setLoaded(true)
        setCollectionData(result)
        setCollectionObj(collectionObj)
      })
    }
  }, [collection])

  const page = data.datoCmsCustomCollection
  let lineItems = []

  if (collection && collectionObj && collectionData) {
    collectionObj.collection.map((collectionItem) => {
      const product = collectionData.find(
        (dataItem) => dataItem.id === collectionItem.productId
      )
      if (product && product.items && product.items.length > 0) {
        const lineItem = product.items.find(
          (item) => item.id === collectionItem.itemId
        )

        lineItems.push({
          product: product,
          lineItem: lineItem,
          collectionItem: collectionItem,
        })
      }
    })
  }

  const hasCollection =
    loaded && collection && collectionData && lineItems.length > 0
  return (
    <Layout
      headerProps={{
        navImage: page.mainNavigationImage,
        containerClass: 'dark',
      }}
    >
      <Section py={{ xs: 20 }}>
        <Container maxWidth="lg">
          <SectionHeading>{page.title}</SectionHeading>

          {loaded && (
            <>
              {hasCollection ? (
                <>
                  <TableContainer>
                    <Table aria-label="custom collection table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Product</TableCell>
                          <TableCell align="center">Quantity</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {lineItems.map((item) => {
                          return (
                            <TableRow
                              key={`${item.product.id}-${item.lineItem.id}`}
                            >
                              <TableCell>
                                <Flex verticalCenter>
                                  <MiniProductImage
                                    imageData={
                                      item.product.productImage.responsiveImage
                                    }
                                  />
                                  <Box ml={3}>
                                    {item.lineItem.productName}
                                    <Box color="lightGrey">
                                      {item.product.collection.collectionName}
                                    </Box>
                                  </Box>
                                </Flex>
                              </TableCell>
                              <TableCell align="center">
                                {item.collectionItem.quantity}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Box mt={10} bg="greyLight" px={11} pt={8} pb={11}>
                    <SectionHeading>Customer</SectionHeading>

                    <Grid container spacing={6}>
                      <FormGridItem xs={12} md={6}>
                        <TextField
                          label="First Name"
                          value={collectionObj.customer.firstName}
                          fullWidth
                          inputProps={{ disabled: true }}
                        />
                      </FormGridItem>
                      <FormGridItem xs={12} md={6}>
                        <TextField
                          label="Surname"
                          value={collectionObj.customer.surname}
                          fullWidth
                          inputProps={{ disabled: true }}
                        />
                      </FormGridItem>
                      <FormGridItem xs={12} md={6}>
                        <TextField
                          label="Email"
                          value={collectionObj.customer.email}
                          fullWidth
                          inputProps={{ disabled: true }}
                        />
                      </FormGridItem>
                      <FormGridItem xs={12} md={6}>
                        <TextField
                          label="Phone"
                          value={collectionObj.customer.phone}
                          fullWidth
                          inputProps={{ disabled: true }}
                        />
                      </FormGridItem>
                      <FormGridItem xs={12}>
                        <TextField
                          label="Your Message"
                          value={collectionObj.customer.message}
                          multiline
                          fullWidth
                          inputProps={{ disabled: true }}
                        />
                      </FormGridItem>
                    </Grid>
                  </Box>
                </>
              ) : (
                <Box mt={10} bg="greyLight" px={11} py={8} textAlign="center">
                  You custom collection is empty. Please view our collections to
                  add products.
                  <InternalButtonLink
                    to="/collections"
                    mx={{ xs: 'auto', lg: 0 }}
                    mt={4}
                    className="double-border sharp dark-bg xl"
                  >
                    View Collections
                  </InternalButtonLink>
                </Box>
              )}
            </>
          )}
          {/* 
          <CollectionForm
            heading={page.formHeading}
            collection={collection}
            collectionItems={lineItems}
            visible={hasCollection}
          /> */}
        </Container>
      </Section>
    </Layout>
  )
}

Collection.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Collection

export const pageQuery = graphql`
  {
    datoCmsCustomCollection {
      introTextNode {
        childMarkdownRemark {
          html
        }
      }
      mainNavigationImage {
        fluid(
          maxWidth: 600
          imgixParams: {
            fit: "crop"
            ar: "3:4"
            w: "600"
            fm: "jpg"
            auto: "compress"
          }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      title
      formHeading
    }
  }
`
